/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}

input[type="range"]::-webkit-slider-thumb {
    width: 1rem;
    -webkit-appearance: none;
      appearance: none;
    height: 1rem;
    cursor: ew-resize;
    background: rgba(100, 21, 255);
    box-shadow: -405px 0 0 400px rgba(100, 21, 255, 0.3);
    border-radius: 50%;
    
}
